import React, { createContext, useContext } from 'react'

import styles from './sonnets-card-grid.module.css'

function SonnetCard (props) {
    return (
        
        props.node && (
            <div className={styles.sonnetCard}>
                <div className={styles.sonnetTitle}> {props.node.title} </div>
                {props.node.lines.map( line => (
                    <pre className={styles.preformattedLine}>{line}</pre>
                ))
                }
            </div>
        )
    );
}

function SonnetsCardGrid (props) {
    return(
      <div className={styles.sonnetsCardGrid}>{
        props.nodes && 
          props.nodes.map( (node, index) => (
              <SonnetCard
                    key = {index}
                  node = {node}
              />
            )
          )
      }
      </div>
    );
  
}

export default SonnetsCardGrid